import { format, parseISO, subMinutes } from "date-fns";
import React, { useState } from "react";
import { FaCheck, FaChevronLeft } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Label, Input } from "reactstrap";
import { CustomConfirmationModal, CustomModal } from "../../common/components/utils/customModal";
import { Spinner } from "../../common/components/spinner/spinner";
import { URL_Scheduling } from "../Services/schedulingService";
import axios from "axios";
import { TbAlertCircle } from "react-icons/tb";
import CharacterRemover from "character-remover";
import { enumStatusScheduling } from "../Utils/enumStatusScheduling";
const styles = {
  div40175257: {
    display: "flex",
    alignItems: "center",
    gap: "2px",
    alignSelf: "stretch",
    paddingBottom: "16px"
  },
  button42214932: {
    display: "flex",
    minHeight: "40px",
    maxHeight: "40px",
    width: "40px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "4px",
    border: "1px",
    paddingTop: "9px",
    paddingRight: "16px",
    paddingBottom: "9px",
    paddingLeft: "16px",
    backgroundColor: "transparent"
  },
  faChevronLeft48364913: {
    color: "#171821",
    width: "14px",
    height: "14px"
  },
  span49425243: {
    color: 'var(--Color-Primary-500, #171821)',
    fontFamily: 'Inter, sans-serif',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal'
  },
  div526716016: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch"
  },
  div54737889: {
    display: "flex",
    paddingTop: "12px",
    paddingRight: "8px",
    paddingBottom: "12px",
    paddingLeft: "8px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    alignSelf: "stretch",
    borderRadius: "4px",
    border: "1px solid var(--Color-Gray-100, #E3E3E3)",
    backgroundColor: "#FBFBFB"
  },
  div59776455: {
    alignSelf: "stretch",
    textAlign: "center",
    fontFamily: "inter,sans-serif",
    fontWeight: "600"
  },
  div79019291: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "1px",
    alignSelf: "stretch"
  },
  div81298543: {
    display: "flex",
    height: "20px",
    paddingTop: "2px",
    paddingBottom: "1px",
    alignItems: "center",
    alignSelf: "stretch",
    color: "var(--Color-Primary-400, #1B1D2B)",
    fontSize: "14px",
    fontFamily: "inter,sans-serif"
  },
  input85559275: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "6px",
    alignSelf: "stretch",
    fontSize: "14px",
    minHeight: "36px",
    maxHeight: "36px",
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingRight: "8px",
    paddingLeft: "8px",
    borderRadius: "4px",
    backgroundColor: "var(--Color-White-100, #FAFAFA)",
    boxShadow: "0px 1px 2px 0px rgba(18, 55, 105, 0.08)"
  },
  label1261813108: {
    display: "flex",
    height: "20px",
    paddingTop: "2px",
    paddingBottom: "1px",
    alignItems: "center",
    alignSelf: "stretch",
    color: "var(--Color-Primary-400, #1B1D2B)",
    fontSize: "14px",
    fontFamily: "inter,sans-serif",
    marginBottom: '0'
  },
  div1312015094: {
    display: 'flex',
    width: '100%'
  },
  input1318214108: {
    flex: '1',
    marginRight: '8px',
    // Espaço entre o input e o botão
    padding: '8px',
    alignItems: "flex-start",
    gap: "6px",
    alignSelf: "stretch",
    fontSize: "14px",
    minHeight: "36px",
    maxHeight: "36px",
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingRight: "8px",
    paddingLeft: "8px",
    borderRadius: "4px",
    backgroundColor: "var(--Color-White-100, #FAFAFA)",
    boxShadow: "0px 1px 2px 0px rgba(18, 55, 105, 0.08)"
    // Espaço entre o input e o botão
  },
  button1432015068: {
    flexShrink: 0,
    padding: '6px 12px',
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    borderRadius: '4px',
    minHeight: '36px',
    maxHeight: '36px',
    background: '#2B60FF',
    color: '#FFF',
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
  },
  div1512016002: {
    display: "flex",
    heigh: "45px",
    minHeight: "45px",
    maxHeight: "45px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    borderRadius: "4px",
    //backgroundColor: "var(--Color-White-500, #F0F0F0)",
    marginTop: "20px",
    marginBottom: "20px"
  }
};
export default function ViewSchedulingData() {
  const location = useLocation();
  const {
    dataScheduling,
    modelClinic
  } = location.state || {};
  const [modalconf, setModalconf] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const delay = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };
  const toogleModal = async props => {
    setModalconf(!modalconf);
    if (props.confirm) {
      unCheck();
    }
  };
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1); // Volta uma página na pilha de navegação
  };
  const unCheck = async () => {
    let map = {
      id: dataScheduling.idScheduling,
      status: 4 //desmarcou
    };
    setLoading(true);
    axios.put(`${URL_Scheduling}/ChangeStatusViaSchedulingLink`, map).then(async resp => {
      const {
        data
      } = resp;
      if (data) {
        setModal(true);
        await delay(3000);
        if (dataScheduling.paymentConfirmed) setModalInfo(true);
        await delay(3000);
        goBack();
        setLoading(false);
      }
    }).catch(() => {
      setLoading(false);
    });
  };
  const myParam = window.location.pathname.split("/")[1];
  const val = () => {
    let value = dataScheduling.services.reduce((partialSum, a) => partialSum + a.value, 0);
    const tel = CharacterRemover.removeAll(dataScheduling.telephone);
    let map = {
      name: dataScheduling.name,
      telephone: tel,
      preSchedulingServiceProvideds: dataScheduling.services,
      document: CharacterRemover.removeAll(dataScheduling.document),
      posScheduling: true,
      idScheduling: dataScheduling.idScheduling,
      value: value
    };
    localStorage.setItem("mapscheduling", JSON.stringify(map));
    navigate("/" + myParam + "/formpayment", {
      replace: false
    });
  };
  const btnDisabled = dataScheduling.status == enumStatusScheduling.Cancelado || dataScheduling.status == enumStatusScheduling.Faltou || dataScheduling.status == enumStatusScheduling.Desmarcou || dataScheduling.status == enumStatusScheduling.Atendido ? true : subMinutes(parseISO(dataScheduling.date, new Date()), modelClinic.uncheckSchedule) > new Date() ? false : true;
  const value = dataScheduling?.services.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
  let payment = JSON.parse(localStorage.getItem("dataClinic"))["payment"];
  let paymentRequired = JSON.parse(localStorage.getItem("dataClinic"))["paymentRequired"];
  return <div>
      {modalconf && <CustomConfirmationModal toogleModal={toogleModal} description={"Deseja desmarcar seu agendamento?"} />}
      {<CustomModal icon={<FaCheck />} description={"Agendamento desmarcado!"} background='#F2FFF2' isOpen={modal} />}
      {<CustomModal icon={<TbAlertCircle />} description={"Contate-nos sobre o pagamento."} background='#FFF9EE' isOpen={modalInfo} />}
      {loading && <Spinner />}
      <div style={styles.div40175257}>
        <button style={styles.button42214932} onClick={() => window.history.back()}>
          <FaChevronLeft style={styles.faChevronLeft48364913} />
        </button>
        <span style={styles.span49425243}>Agendamentos</span>
      </div>

      <div style={styles.div526716016}>
        <div style={styles.div54737889}>
          <div className="s-1300-com" style={styles.div59776455}>
            {format(new Date(dataScheduling.date), "dd/MM/yyyy", {})} às {format(new Date(dataScheduling.date), "HH:mm") + "" + " com " + dataScheduling.professional}
          </div>
          {dataScheduling.services.map((e, index) => <div key={index} className="s-1300-com" style={styles.div59776455}>
              {e.nameService} -{" "}
              {e.value.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL"
          })}
            </div>)}
             {dataScheduling.packages.map((e, index) => <div key={index} className="s-1300-com" style={styles.div59776455}>
              {e.name}
            </div>)}
             {dataScheduling.subscriptionPlan.map((e, index) => <div key={index} className="s-1300-com" style={styles.div59776455}>
              {e.name}
            </div>)}
        </div>

        <div style={styles.div79019291}>
          <div style={styles.div81298543}>Nome</div>
          <Input style={styles.input85559275} disabled={true} value={dataScheduling.name} />
        </div>

        <div style={styles.div79019291}>
          <div style={styles.div81298543}>
            Valor Total
          </div>
          <Input style={styles.input85559275} disabled={true} value={value.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL"
        })} />
        </div>
        <div style={styles.div79019291}>
        <div style={styles.div81298543}>Valor Pago</div>
          <Input style={styles.input85559275} disabled={true} value={dataScheduling.amountPaid.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL"
        })} />
        </div>
        <div style={styles.div79019291}>
          <Label style={styles.label1261813108}>
            Status do Pagamento
          </Label>
          <div style={styles.div1312015094}>
            <Input type="text" style={styles.input1318214108} disabled={true} value={dataScheduling.paymentConfirmed ? "Pago" : "Aberto"} />
            {!dataScheduling.paymentConfirmed && dataScheduling.status != enumStatusScheduling.Desmarcou && (payment || paymentRequired) && value > 0 ? <button onClick={val} style={styles.button1432015068}>
                Pagar
              </button> : null}
          </div>
        </div>
        <div style={styles.div1512016002}>
          <Button color="dark" onClick={toogleModal} disabled={btnDisabled} style={{
          width: "100%",
          fontWeight: "600",
          opacity: btnDisabled ? 0.25 : null
        }}>
            Desmarcar
          </Button>
        </div>
      </div>
    </div>;
}