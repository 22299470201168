import React, { Component } from "react";
import { Route, Routes, } from "react-router-dom";
import Scheduling from './Components/Scheduling/Scheduling'
import SchedulingsSearch from "./Components/SchedulingsSearch/SchedulingsSearch";
import Base from "./Components/Layout/Base"
import SelectServices from "./Components/SelectServices/SelectServices";
import Professionals from "./Components/Professionals/Professionals";
import BasePage from "./Components/Layout/BasePage";
import Anamnese from "./Components/Anamnese/Anamnese";
import ErrorBoundary from './Components/Errors/ErrorBoundary';
import FormPayment from "./Components/Payment/FormPayment";
import Location from "./Components/SelectServices/Location";
import { InsertPhonePage } from "./Components/SchedulingsSearch/InsertPhonePage";
import ViewSchedulingData from "./Components/SchedulingsSearch/ViewSchedulingData";

const Router = () => {
    const currentKey = window.location.pathname.split('/')[1] || '/';
    if (currentKey == "anamnese") {
        return (
            <BasePage>
                <Routes>
                    <Route path="/anamnese/:guid" element={<Anamnese />} />
                </Routes>
            </BasePage>
        )
    } else {
        return (
            <ErrorBoundary>
                <Base>
                    <Routes>
                        <Route path="/:param1" index element={<SelectServices />} />
                        <Route path="/:param1/scheduling" element={<Scheduling />} />
                        <Route path="/:param1/schedulingssearch" element={<SchedulingsSearch />} />
                        <Route path="/:param1/schedulingssearch/:tel" element={<SchedulingsSearch />} />
                        <Route path="/:param1/professionals" element={<Professionals />} />
                        <Route path="/:param1/formpayment" element={<FormPayment />} />
                        <Route path="/:param1/location" element={<Location />} />
                        <Route path="/:param1/insertphonepage" element={<InsertPhonePage />} />
                        <Route path="/:param1/viewschedulingdata" element={<ViewSchedulingData />} />
                    </Routes>
                </Base>
            </ErrorBoundary>
        )
    }
}
export default Router;

export class Loading extends Component {
    render() {
        return (<div className="text-center mt-5">Carregando...</div>)
    }
}