import React, { Component } from "react";
import { Button, Input, Col, FormGroup, Label } from "reactstrap";
import axios from "axios";
import { URL_PreScheduling } from "../Services/preSchedulingService";
import { FaSpinner } from "react-icons/fa";
import { IoClipboardOutline } from "react-icons/io5";
import { FiMapPin } from "react-icons/fi";
import { FiSearch } from "react-icons/fi";
import { FiClock } from "react-icons/fi";
import { FiCalendar } from "react-icons/fi";
const styles = {
  div29946554: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    gap: "6px",
    alignSelf: "stretch",
    marginBottom: "24px"
  },
  div32834786: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "4px",
    flexGrow: "1"
  },
  div35584764: {
    position: "relative",
    width: "100%"
  },
  input36314314: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minHeight: "40px",
    maxHeight: "40px",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid #ccc",
    padding: "12px"
  },
  span43344740: {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    pointerEvents: "none"
  },
  fiSearch46544713: {
    fontSize: "22px",
    color: "#818898"
  },
  div48026534: {
    display: "flex",
    alignItems: "flex-start",
    gap: "4px"
  },
  button50375727: {
    display: "flex",
    height: "40px",
    borderColor: "#171821",
    width: "50px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "4px",
    backgroundColor: "var(--Color-Primary-500, #171821)"
  },
  button58076488: {
    display: "flex",
    height: "40px",
    width: "50px",
    borderColor: "#171821",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "4px",
    backgroundColor: "var(--Color-Primary-500, #171821)"
  },
  div656819847: {
    flexDirection: "column",
    alignItems: "center"
  },
  div679917824: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    alignContent: "flex-start",
    gap: "24px",
    alignSelf: "stretch",
    flexWrap: "wrap"
  },
  div734517742: {
    minWidth: "340px",
    minHeight: "290px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: "1 0 0"
  },
  div780917710: {
    display: "flex",
    minWidth: "340px",
    minHeight: "290px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: "1 0 0",
    borderRadius: "4px",
    border: "1px solid var(--Color-Gray-100, #E3E3E3)"
  },
  img85279214: {
    display: "flex",
    paddingTop: "8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px"
  },
  div1001311256: {
    display: "flex",
    minHeight: "50px",
    maxHeight: "50px",
    paddingTop: "16px",
    paddingRight: "8px",
    paddingBottom: "16px",
    paddingLeft: "8px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    color: "var(--Color-Primary-500, #171821)",
    textAlign: "center",
    fontFamily: "Inter, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal"
  },
  div1128614339: {
    display: "flex",
    paddingTop: "8px",
    paddingRight: "8px",
    paddingBottom: "16px",
    paddingLeft: "8px",
    flexDirection: "column",
    alignItems: "center",
    gap: "24px",
    alignSelf: "stretch",
    borderBottom: "1px solid var(--Color-Gray-100, #E3E3E3)"
  },
  div1208513251: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    overflow: "hidden",
    color: "var(--Color-Primary-500, #171821)",
    textAlign: "center",
    textOverflow: "ellipsis",
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal"
  },
  fiClock1302413149: {
    width: "16px",
    height: "16px"
  },
  div1338914269: {
    display: "webkit-box",
    alignSelf: "stretch",
    overflow: "hidden",
    color: "var(--Color-Primary-500, #171821)",
    textAlign: "center",
    textOverflow: "ellipsis",
    fontFamily: "inter,sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal"
  },
  div1436917676: {
    display: "flex",
    minHeight: "55px",
    maxHeight: "55px",
    width: "100%",
    paddingTop: "16px",
    paddingRight: "8px",
    paddingBottom: "16px",
    paddingLeft: "8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    alignSelf: "self",
    borderRadius: "4px",
    margin: "0px"
  },
  div1524117640: {
    display: "flex",
    padding: "12px",
    alignItems: "center",
    gap: "12px"
  },
  label1564317602: {
    display: "flex",
    color: "var(--Color-Primary-500, #171821)",
    textAlign: "center",
    fontFamily: "inter,sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    margin: "0px",
    alignItems: "0px",
    gap: "10px"
  },
  input1645017231: {
    display: "flex",
    margin: "0px",
    width: "18px",
    height: "18px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px"
  },
  div1784419363: {
    marginTop: "20px"
  },
  button1798719337: {
    backgroundColor: "#007bff",
    color: "var(--Color-White-Pure, #FFF)",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    zIndex: 1000,
    opacity: 1,
    // Ensures the button is not transparent
    borderRadius: "4px",
    background: "var(--Color-Primary-500, #171821)",
    fontFamily: "inter,sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    width: "100%",
    height: "45px",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    textAlign: "center",
    padding: "12px"
  },
  h51949419759: {
    fontFamily: "inter, sans-serif"
  }
};
export default class SelectServices extends Component {
  constructor(props) {
    super(props);
    const myParam = window.location.pathname.split("/")[1];
    this.state = {
      listProcedures: [],
      myParam: myParam,
      navigate: false,
      loading: true,
      nameProcedure: "",
      listChecked: []
    };
  }
  componentDidMount() {
    this.consultProcedures();
  }
  consultProcedures = async () => {
    const {
      myParam,
      nameProcedure
    } = this.state;
    await axios.get(`${URL_PreScheduling}/GetProcedures`, {
      params: {
        aliasClinic: myParam,
        nameProcedure: nameProcedure
      }
    }).then(resp => {
      const {
        data
      } = resp;
      this.setState({
        listProcedures: data,
        loading: false
      });
    });
  };
  consultProcedureLength = async e => {
    if (e.length == 0 || e.length > 3) {
      await this.setState({
        nameProcedure: e
      });
      await this.consultProcedures();
    }
  };
  addService = (service, x) => {
    const {
      listProcedures,
      listChecked
    } = this.state;
    listProcedures.forEach(element => {
      if (element.id == service.id) {
        element.checked = x.target.checked;
        if (x.target.checked) {
          let map = {
            idProcedure: element.id,
            value: element.value,
            redemptionPoints: element.redemptionPoints,
            loyaltyPoint: element.loyaltyPoints,
            duration: element.duration,
            description: element.description
          };
          listChecked.push(map);
        } else {
          for (var i = 0; i < listChecked.length; i++) {
            if (listChecked[i].idProcedure === element.id) {
              listChecked.splice(i, 1);
            }
          }
        }
      }
    });
    let listProcedureId = [];
    listChecked.forEach(element => {
      listProcedureId.push(element.idProcedure);
    });
    localStorage.setItem("listProcedureId", JSON.stringify(listProcedureId));
    localStorage.setItem("listProcedures", JSON.stringify(listChecked));
    this.setState({
      listProcedures,
      listChecked: listChecked
    });
  };
  render() {
    const {
      listProcedures,
      myParam,
      loading,
      listChecked
    } = this.state;
    return <div>
        {loading ? <div className="text-center mt-5">
            <FaSpinner color={"blue"} className="icon_pulse" />
          </div> : <div>
            <div style={styles.div29946554}>
              <div style={styles.div32834786}>
                <div style={styles.div35584764}>
                  <input onChange={e => this.consultProcedureLength(e.target.value)} type="text" placeholder="Pesquisar..." style={styles.input36314314} />
                  <span style={styles.span43344740}>
                    <FiSearch style={styles.fiSearch46544713} />
                  </span>
                </div>
              </div>
              <div style={styles.div48026534}>
                <div className="">
                  <Button href={myParam + "/insertphonepage"} style={styles.button50375727}>
                    <FiCalendar size={18} color="white" />
                  </Button>
                </div>
                <div className="">
                  <Button style={styles.button58076488} href={myParam + "/location"}>
                    <FiMapPin size={17} color="white" />
                  </Button>
                </div>
              </div>
            </div>
            <div style={styles.div656819847}>
              {listProcedures.length > 0 ? <div>
                  <div className="" style={styles.div679917824}>
                    {listProcedures.map(e => e.showOnSchedule > 0 ? <div key={e.id} style={styles.div734517742}>
                          <div style={styles.div780917710}>
                            {e.thumbFile != null ? <img width={100} height={100} className="mb-1 img-fluid rounded-circle thumb75" src={"data:image/png;base64," + e.thumbFile} alt="Contact" style={styles.img85279214} /> : <img width={100} height={100} className="mb-1 img-fluid rounded-circle thumb75" src="/Image/sem_foto.png" alt="Contact" style={styles.img85279214} />}

                            <div style={styles.div1001311256}>
                              {e.description}
                            </div>
                            <div style={styles.div1128614339}>
                              {e.duration > 0 ? <div style={styles.div1208513251}>
                                  <FiClock style={styles.fiClock1302413149} />
                                  {` ${e.duration} minutos`}
                                </div> : null}
                              {e.note == undefined ? null : <div style={styles.div1338914269}>
                                  {e.note}
                                </div>}
                            </div>
                            <div style={styles.div1436917676}>
                              <div key={e.id} style={styles.div1524117640}>
                                <Label style={styles.label1564317602}>
                                  <Input color="dark" style={styles.input1645017231} type="checkbox" onChange={x => this.addService(e, x)} checked={e.checked ?? false} />
                                  {e.displayValue ? e.value.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL"
                        }) : null}
                                </Label>
                              </div>
                            </div>
                          </div>
                        </div> : null)}
                  </div>
                  <div style={styles.div1784419363}>
                    <Button id="free-offer-button" disabled={listChecked.length > 0 ? false : true} color="dark" href={myParam + "/professionals"} style={styles.button1798719337}>
                      Agendar
                    </Button>
                  </div>
                </div> : <div className="row">
                  <Col>
                    <h5 style={styles.h51949419759} className="text-center">
                      Serviços não localizados!
                    </h5>
                  </Col>
                </div>}
            </div>
          </div>}
      </div>;
  }
}