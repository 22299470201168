import React, { Component } from 'react';
import { FormGroup, Button, CardHeader, Row, Col, Card, CardBody, CardText } from 'reactstrap';
import axios from 'axios';
import Switch from 'react-input-switch';
import SignatureCanvas from 'react-signature-canvas';
import { URL_AnamnesisClient, URL_AnamnesisGroup } from '../Services/anamnesisService';
import './styles.css';
import swal from 'sweetalert';
import { FaSpinner } from 'react-icons/fa';
import { PatternFormat } from 'react-number-format';
const styles = {
  patternFormat1544715747: {
    fontWeight: 'normal'
  }
};
export default class Anamnese extends Component {
  sigCanvas = React.createRef();
  constructor(props) {
    super(props);
    const myParamGuid = window.location.pathname.split('/')[2];
    this.state = {
      loading: false,
      formAnamnesisGroup: {
        anamnesisGroups: [],
        signature: '',
        responseDataClinic: {}
      },
      loadingForm: true,
      myParamGuid: myParamGuid,
      modelClinic: {}
    };
  }
  componentDidMount = async () => {
    const {
      myParamGuid
    } = this.state;
    if (myParamGuid) await this.consultAnamnesisByGuid();
  };
  consultAnamnesisByGuid = async () => {
    const {
      myParamGuid
    } = this.state;
    await axios.get(`${URL_AnamnesisGroup}/${myParamGuid}`).then(resp => {
      const {
        data
      } = resp;
      if (data) {
        this.setState({
          loadingForm: false,
          formAnamnesisGroup: data
        });
      }
    });
  };
  toogleHandler(e, id) {
    const {
      formAnamnesisGroup
    } = this.state;
    formAnamnesisGroup.anamnesisGroups.forEach(element => {
      element.anamnesis.forEach(element => {
        if (element.id == id) {
          element.marked = e == 1 ? true : false;
        }
      });
    });
    this.setState({
      formAnamnesisGroup
    });
  }
  inputHandler(e, id) {
    const {
      formAnamnesisGroup
    } = this.state;
    formAnamnesisGroup.anamnesisGroups.forEach(element => {
      element.anamnesis.forEach(element => {
        if (element.id == id) {
          element.typedText = e.target.value;
        }
      });
    });
    this.setState({
      formAnamnesisGroup
    });
  }
  checkboxHandler(e, id) {
    const {
      formAnamnesisGroup
    } = this.state;
    formAnamnesisGroup.anamnesisGroups.forEach(element => {
      element.anamnesis.forEach(element => {
        element.anamnesisListOptions.forEach(element => {
          if (element.id == id) {
            element.marked = e.target.checked;
          }
        });
      });
    });
    this.setState({
      formAnamnesisGroup
    });
  }
  save = async () => {
    const {
      formAnamnesisGroup,
      myParamGuid
    } = this.state;
    if (formAnamnesisGroup.anamnesisGroups.length > 0) {
      this.setState({
        loading: true
      });
      let data = {
        anamnesisGroups: formAnamnesisGroup.anamnesisGroups,
        guid: myParamGuid
      };
      const formData = new FormData();
      formData.append('file', this.getBase64Image());
      formData.append('data', JSON.stringify(data));
      if (myParamGuid) {
        await axios.put(`${URL_AnamnesisClient}/PutFromLink`, formData).then(resp => {
          const {
            data
          } = resp;
          if (data) {
            swal({
              title: "Sucesso!",
              text: "Sua ficha foi salva com sucesso!",
              icon: 'success'
            });
          }
          this.consultAnamnesisByGuid();
        }).catch(() => this.setState({
          loading: false
        }));
      }
      this.setState({
        loading: false
      });
    }
  };
  getBase64Image() {
    if (this.sigCanvas.current != null) {
      var dataURL = this.sigCanvas.current.toDataURL('image/png');
      var contains = this.sigCanvas.current.getSignaturePad();
      if (contains.points.length > 0) return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");else return '';
    } else return '';
  }
  clearSignature() {
    if (this.sigCanvas.current != null) this.sigCanvas.current.clear();
  }
  render() {
    const {
      formAnamnesisGroup,
      loading,
      loadingForm,
      modelClinic
    } = this.state;
    return <div className='container'>
                {loadingForm ? <div className='text-center mt-5'><FaSpinner size={45} color={'blue'} className="icon_pulse" />
                    </div> : <div>
                        <Header modelClinic={formAnamnesisGroup.responseDataClinic} />

                        <Card>
                            <CardHeader tag={'h5'}>
                                Sua Ficha Anamnese
                            </CardHeader>
                            <CardBody>
                                <FormGroup>
                                    <Row>
                                        <Col>
                                            {formAnamnesisGroup.anamnesisGroups.map(g => <div key={g.id}>
                                                    <div className="p-2 mb-2 bg-info text-white">{g.description}</div>
                                                    <div>{g.anamnesis.map(a => <div key={a.id}>
                                                            {a.status == 0 ? <Row className='mb-3'>
                                                                    <Col md={4}>
                                                                        <p className="text-dark">{a.description}</p>
                                                                    </Col>
                                                                    <Col md={2}>
                                                                        {a.toMark == true ? <Switch value={a.marked == true ? 1 : 0} onChange={e => this.toogleHandler(e, a.id)} styles={{
                              track: {
                                backgroundColor: 'grey'
                              },
                              trackChecked: {
                                backgroundColor: 'green'
                              },
                              button: {
                                backgroundColor: 'white'
                              },
                              buttonChecked: {
                                backgroundColor: 'white'
                              }
                            }} /> : null}
                                                                    </Col>
                                                                    <Col md={4}>
                                                                        {a.containText == true ? <input className="form-control form-control-sm" value={a.typedText} placeholder="preencher..." onChange={e => this.inputHandler(e, a.id)} type="text" /> : null}
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <Row>
                                                                            {a.anamnesisListOptions.map(o => <Col key={o.id}>
                                                                                    <div>
                                                                                        <input type="checkbox" checked={o.marked} onChange={e => this.checkboxHandler(e, o.id)} />
                                                                                        {' '}<label>{o.text}</label>
                                                                                    </div>
                                                                                </Col>)}
                                                                        </Row>
                                                                    </Col>
                                                                </Row> : null}
                                                        </div>)}</div>
                                                </div>)}
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    {!formAnamnesisGroup.signature ? <Row>
                                            <Col>
                                                <SignatureCanvas penColor='blue' ref={this.sigCanvas} clearOnResize={true} canvasProps={{
                    className: 'sigPad'
                  }} backgroundColor='Gainsboro' />
                                            </Col>
                                        </Row> : <Row>
                                            <Col>
                                                <img className='sigPad' src={"data:image/png;base64," + formAnamnesisGroup.signature} alt="Contact" />
                                            </Col>
                                        </Row>}
                                </FormGroup>
                                <Button className="btn btn-sm btn-sucess" color="success" onClick={this.save} disabled={loading}>{loading && <i className="fas fa-spinner fa-spin" />}
                                    {loading && <span> Salvando...</span>}
                                    {!loading && <i className="fa fa-check" />}
                                    {!loading && <span> Salvar</span>}
                                </Button>{' '}
                                <Button className="ml-auto" disabled={!formAnamnesisGroup.signature ? false : true} size='sm' onClick={e => this.clearSignature()} color='primary'>

                                    <em className="fas fa-times-circle"></em>
                                    {" Limpar assinatura"}
                                </Button>
                            </CardBody>
                        </Card>
                    </div>}
            </div>;
  }
}
class Header extends Component {
  render() {
    const {
      modelClinic
    } = this.props;
    return <div>
                <Card body className='border-1'>
                    <Row>
                        <Col sm="3">
                            <div className='d-flex justify-content-center'>
                                {<img className="img-fluid w-25 m-auto" src={modelClinic.file != undefined ? "data:imageFile/png;base64," + modelClinic.file : "image/IGICoficialazul.png"} />}
                            </div>

                        </Col>
                        <Col sm="6" className='text-left my-auto'>
                            <CardText tag="h5" className='m-0'>
                                <span>{modelClinic.corporateName}</span>
                            </CardText>
                            <CardText className='text-secondary m-0'>
                                {modelClinic.address} {modelClinic.complement} - B. {modelClinic.bairro}
                            </CardText>
                            <CardText className='text-secondary m-0'>
                                {modelClinic.nameCity} / {modelClinic.nameState} -
                                CEP: {<PatternFormat format='##.###-###' displayType={'text'} value={modelClinic.zipCode} style={styles.patternFormat1544715747} />}
                            </CardText>
                            <CardText className='text-secondary m-0'>
                                {<PatternFormat format='(##) #####-####' displayType={'text'} value={modelClinic.cellPhone} style={styles.patternFormat1544715747} />}
                            </CardText>

                        </Col>
                    </Row>
                </Card>
            </div>;
  }
}