import React, { Component, useState, useEffect, useRef } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { Loader } from '@googlemaps/js-api-loader';
import { FaChevronLeft } from "react-icons/fa";
import { FiCopy } from "react-icons/fi";
const styles = {
  div17398456: {
    width: "100%",
    paddingBottom: "32px",
    flexDirection: "column",
    alignItems: "center",
    gap: "24px",
    alignSelf: "stretch"
  },
  div19783291: {
    display: "flex",
    alignItems: "center",
    gap: "2px",
    alignSelf: "stretch"
  },
  button21642916: {
    display: "flex",
    minHeight: "40px",
    maxHeight: "40px",
    width: "40px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "4px",
    border: "1px",
    paddingTop: "9px",
    paddingRight: "16px",
    paddingBottom: "9px",
    paddingLeft: "16px",
    backgroundColor: "transparent"
  },
  faChevronLeft28192895: {
    color: "171821",
    width: "14px",
    height: "14px"
  },
  div29303275: {
    color: "var(--Color-Primary-500, #171821)",
    fontFamily: "inter, sans-serif",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal"
  },
  div33038042: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
    paddingTop: "24px",
    paddingBottom: "24px",
    alignSelf: "self"
  },
  div35936456: {
    display: "flex",
    height: "60px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "1px",
    alignSelf: "stretch"
  },
  label38704353: {
    display: "flex",
    height: "20px",
    paddingTop: "2px",
    paddingBottom: "1px",
    alignItems: "center",
    flexShrink: "0",
    alignSelf: "stretch",
    fontWeight: "500",
    fontFamily: "inter, sans-serif",
    fontSize: "14px"
  },
  div43676438: {
    display: "flex",
    position: "relative",
    width: "100%" // Ensure the container takes full width
  },
  input45905349: {
    width: "100%",
    // Full width for the input
    minHeight: "36px",
    maxHeight: "36px",
    paddingTop: "6px",
    paddingRight: "40px",
    // Adjust padding to make room for the icon
    paddingBottom: "6px",
    paddingLeft: "8px",
    borderRadius: "4px",
    background: "#FAFAFA",
    fontSize: "14px"
  },
  fiCopy53655845: {
    color: '#1B1D2B',
    position: "absolute",
    right: "10px",
    // Position it inside the textbox
    top: "50%",
    transform: "translateY(-50%)",
    cursor: "pointer",
    height: "20px",
    width: "20px"
  },
  div59306400: {
    position: 'absolute',
    top: '-30px',
    left: '95%',
    transform: 'translateX(-50%)',
    backgroundColor: '#333',
    color: '#fff',
    padding: '5px',
    borderRadius: '4px',
    fontSize: '12px',
    zIndex: 1
  },
  input72448008: {
    display: "flex",
    minHeight: "36px",
    maxHeight: "36px",
    paddingTop: "6px",
    paddingRight: "8px",
    paddingBottom: "6px",
    paddingLeft: "8px",
    gap: "8px",
    alignSelf: "stretch",
    borderRadius: "4px",
    background: "#FAFAFA",
    fontSize: "14px"
  },
  div80548442: {
    height: "350px",
    alignSelf: "stretch",
    borderRadius: "8px"
  },
  div1017910242: {
    height: '400px',
    width: '100%'
  }
};
export default class Location extends Component {
  state = {
    modelClinic: {},
    load: true,
    tooltipVisible: false
  };
  componentDidMount = async () => {
    let data = await JSON.parse(localStorage.getItem("dataClinic"));
    this.setState({
      modelClinic: data,
      load: false
    });
  };

  // toggleClick = () => {
  //   const { modelClinic } = this.state;

  //   const end = `${modelClinic?.address}, ${modelClinic?.number ?? ""
  //     } - ${modelClinic?.bairro}`
  //   if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
  //     return navigator.clipboard.writeText(end);
  //   }
  // };
  handleCopy = () => {
    // Texto a ser copiado
    const {
      modelClinic
    } = this.state;
    const textToCopy = `${modelClinic?.address}, ${modelClinic?.number ?? ""} - ${modelClinic?.bairro}`;

    // Copiar o texto para a área de transferência
    navigator.clipboard.writeText(textToCopy).then(() => {
      // Mostrar o tooltip
      // setTooltipVisible(true);
      this.setState({
        tooltipVisible: true
      });

      // Esconder o tooltip após 2 segundos
      setTimeout(() => {
        // setTooltipVisible(false);
        this.setState({
          tooltipVisible: false
        });
      }, 2000);
    }).catch(err => {
      console.error('Erro ao copiar texto: ', err);
    });
  };
  render() {
    const {
      modelClinic,
      load,
      tooltipVisible
    } = this.state;
    return <div style={styles.div17398456}>
        <div style={styles.div19783291}>
          <button style={styles.button21642916} onClick={() => window.history.back()}>
            <FaChevronLeft style={styles.faChevronLeft28192895} />
          </button>

          <div style={styles.div29303275}>
            Localização
          </div>
        </div>

        <div style={styles.div33038042}>
          <div style={styles.div35936456}>
            <label style={styles.label38704353}>
              Endereço
            </label>
            <div style={styles.div43676438}>
              <input disabled={true} type="text" className="form-control" value={`${modelClinic?.address}, ${modelClinic?.number ?? ""} - ${modelClinic?.bairro}`} style={styles.input45905349} />
              <FiCopy onClick={() => this.handleCopy()} style={styles.fiCopy53655845}>
              </FiCopy>
              {/* Tooltip */}
              {tooltipVisible && <div style={styles.div59306400}>
                  Endereço copiado!
                </div>}
            </div>
          </div>

          <div style={styles.div35936456}>
            <label style={styles.label38704353}>
              Telefone
            </label>
            <input disabled={true} value={!modelClinic.cellPhone ? "" : formatPhoneNumber(modelClinic.cellPhone)} className="form-control" type="text" style={styles.input72448008} />
          </div>
        </div>

        <div className="row" style={styles.div80548442}>
          <div className="col">
            {!load && <MapContainer address={`${modelClinic?.address}${modelClinic?.number ?? ""} - ${modelClinic?.bairro}`} />}
          </div>
        </div>
      </div>;
  }
}
function formatPhoneNumber(phoneNumber) {
  // Remove qualquer caractere que não seja um número
  if (phoneNumber) {
    phoneNumber = phoneNumber.replace(/\D/g, "");

    // Adiciona a máscara (XX) XXXXX-XXXX
    if (phoneNumber.length > 10) {
      phoneNumber = phoneNumber.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    } else {
      // Caso seja um número com 10 dígitos (sem o nono dígito)
      phoneNumber = phoneNumber.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    }
    return phoneNumber;
  }
}
function MapContainer(props) {
  const mapRef = useRef(null);
  const [address] = useState(props.address);
  useEffect(() => {
    const loader = new Loader({
      apiKey: "AIzaSyDY33IZvP-Iop6ck82jSrUrO67ZY0k5IWo",
      version: 'weekly',
      libraries: ['places']
    });

    // Nova forma de carregar a API com conformidade
    (async () => {
      try {
        const google = await loader.load(); // Uso assíncrono para obter o objeto google

        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({
          address: address
        }, (results, status) => {
          if (status === 'OK') {
            const map = new google.maps.Map(mapRef.current, {
              center: results[0].geometry.location,
              zoom: 14
            });
            new google.maps.Marker({
              map: map,
              position: results[0].geometry.location
            });
          } else {
            console.error('Geocoding failed: ' + status);
          }
        });
      } catch (e) {
        console.error('Erro ao carregar o Google Maps: ', e);
      }
    })();
  }, [address]);
  return <div ref={mapRef} style={styles.div1017910242} />;
}
;